<template>
  <!-- Dialog Add Dividends -->
  <ib-dialog
    id="dialog-story-mode-add-dividends"
    empty-header
    :visible="dialogAddDividendsVisible"
    fullscreen
    @open="openDialog"
    @close="closeDialog"
    @closed="closedDialog"
  >
    <!-- Mobile title -->
    <template #title>
      <ib-dialog-container class="d-md-none">
        <h2>
          {{ $tc('addDividend') }}
        </h2>
      </ib-dialog-container>
    </template>
    <!-- /Mobile title -->

    <!-- Content Wrapper -->
    <ib-dialog-container class="content-wrapper px-1" max-width="1440px">
      <!-- Header -->
      <add-dialog-header>
        <template #left>
          <!-- Title -->
          <h2 class="m-0">
            {{ $tc('addDividend') }}
          </h2>
          <!-- /Title -->
        </template>
      </add-dialog-header>
      <!-- /Header -->
      <!-- Info Guide -->
      <info-guide-add-dialog
        class="mb-3 d-block d-xl-none"
        :visible="guideVisible"
        :title="$t('pages.businessGuide.profitAndCashFlow.addDialog.guide.dividends.title')"
        :text="$t('pages.businessGuide.profitAndCashFlow.addDialog.guide.dividends.text')"
        @click="onToggleDialogGuide"
      />
      <!-- Info Guide -->

      <el-row :gutter="20">
        <!-- Left Side -->
        <el-col :xl="14">
          <!-- Inner Wrapper -->
          <div class="inner-wrapper">
            <div class="grid-wrapper">
              <el-row :gutter="60">

                <!-- Left Side -->
                <el-col :md="12">
                  <!-- Form Wrapper -->
                  <div class="startup-form-wrapper">

                    <!-- Form Inner -->
                    <div class="startup-form-inner-top">

                      <!-- Form -->
                      <el-form :model="form">

                        <!-- Name -->
                        <ib-input
                          v-model="form.name"
                          :label="$t('fields.name')"
                        />
                        <!-- /Name -->

                        <!-- Amount -->
                        <el-form-item class="currency-related-form-item" :label="$t('fields.amount')">
                          <span class="currency-symbol">{{ currencySymbol }}</span>
                          <div class="currency-related-input amount el-input">
                            <currency-input
                              v-model="form.amount"
                              class="el-input__inner"
                              :distraction-free="false"
                              :currency="null"
                              :value-range="{min: 0, max: 999999999}"
                              :precision="{min: 0, max: 2}"
                              locale="en-US"
                            />
                          </div>
                        </el-form-item>
                        <!-- /Amount -->

                        <!-- One Time Or Constant -->
                        <form-switcher
                          v-model="form.oneTime"
                          :options="[{
                            title: $t('oneTime'),
                            value: true,
                            callback: changeToOneTime
                          },{
                            title: $t('constant'),
                            value: false,
                            callback: changeToConstant
                          }]"
                        />
                        <!-- /One Time Or Constant -->

                        <!-- Dialog Add Dividends Form Inner Bottom -->
                        <div class="startup-form-inner-bottom">

                          <!-- Distribution Frequency -->
                          <div class="input-group" :class="{ 'visible' : form.oneTime === false }">
                            <el-form-item
                              class="input-inside-label"
                              :label="$t('pages.businessGuide.profitAndCashFlow.addDialog.fields.dividends.distributionFrequency')"
                              :class="{ 'focused': focus.distributionFrequency === true }"
                            >
                              <ib-select
                                v-model="form.distributionFrequency"
                                filterable
                                placeholder=" "
                                popper-class="promo-dropdown"
                                :popper-append-to-body="false"
                              >
                                <ib-option
                                  v-for="distributionFrequency in options.distributionFrequencies"
                                  :key="distributionFrequency.id"
                                  :label="distributionFrequency.label"
                                  :value="distributionFrequency.value"
                                />
                              </ib-select>
                            </el-form-item>
                          </div>
                          <!-- /Distribution Frequency -->

                          <!-- When will it occur -->
                          <el-form-item
                            class="input-inside-label yellow"
                            :label="$t('fields.whenWillItOccur')"
                            :class="{ 'focused' : focus.whenWillItOccur === true }"
                          >
                            <el-date-picker
                              v-model="form.whenWillItOccur"
                              :clearable="false"
                              :editable="false"
                              :picker-options="pickerOptions"
                              :popper-append-to-body="false"
                              format="MMM, yyyy"
                              class="date-picker-monthly"
                              popper-class="date-picker-monthly-dropdown-modal"
                              type="month"
                              value-format="yyyy-MM-dd"
                              @blur="toggleFocus('whenWillItOccur')"
                              @change="toggleFocus('whenWillItOccur')"
                              @focus="toggleFocus('whenWillItOccur')"
                            />
                          </el-form-item>
                          <!-- /When will it occur -->

                        </div>
                        <!-- /Dialog Add Dividends Form Inner Bottom -->

                      </el-form>
                      <!-- /Form -->

                    </div>
                    <!-- /Form Inner -->

                  </div>
                  <!-- /Form Wrapper -->

                  <div class="my-4 my-md-0">

                    <!-- Divider -->
                    <ib-divider block class="my-4" />
                    <!-- /Divider -->

                    <el-row :gutter="10" class="d-flex justify-content-sm-end mt-4 mb-4">
                      <el-col :span="12">
                        <!-- Close -->
                        <ib-button variant="white" class="w-100" size="lg" font-family="regular"
                                   @click="closeDialogOnButton"
                        >
                          {{ $t('close') }}
                        </ib-button>
                        <!-- /Close -->
                      </el-col>

                      <el-col :span="12" class="text-right">
                        <ib-button
                          class="w-100 text-uppercase"
                          size="lg"
                          :disabled="!isAddEnabled"
                          :loading="loading"
                          @click="saveDividend"
                        >
                          {{ form.id === 0 ? $t('add') : $t('save') }}
                        </ib-button>
                      </el-col>
                    </el-row>
                  </div>

                </el-col>
                <!-- /Left Side -->

                <!-- Right Side -->
                <el-col :md="12">

                  <!-- Dividends Title -->
                  <!--                  <h2>{{ $tc('dividend', 2) }}</h2>-->
                  <!-- /Dividends Title -->
                  <template v-if="!$store.state.idea.storyMode.forecast.profitAndCashFlow.dividends.length">
                    <div class="d-flex flex-column justify-content-center align-items-center mt-5">
                      <img class="add-dialog-folder-icon" src="@/assets/img/icons/add-dialog-folder.svg" alt="">
                      <p class="add-dialog-folder-text font-outfit-regular">
                        {{ $t('createdItemsWillAppearHere') }}
                      </p>
                    </div>
                  </template>

                  <!-- Dividends Added -->
                  <div ref="cardHolder" class="costs-added">

                    <!-- Dividends Added List -->
                    <div
                      v-for="dividend in $store.state.idea.storyMode.forecast.profitAndCashFlow.dividends"
                      :key="dividend.id" class="startup-cost-card"
                    >

                      <!-- Dividend Card Top -->
                      <div class="card-top">

                        <!-- Dividend Flex Inner -->
                        <div class="flex-inner">

                          <!-- Dividend Image -->
                          <div class="card-image-round default" />
                          <!-- /Dividend Image -->

                          <!-- Dividend Name & Dividend Date -->
                          <div>

                            <!-- Dividend Name -->
                            <h3>{{ dividend.name }}</h3>
                            <!-- /Dividend Name -->

                            <!-- Dividend Date -->
                            <p class="date">
                              {{ getFormattedDate(dividend.whenWillItOccur) }}
                            </p>
                            <!-- /Dividend Date -->

                          </div>
                          <!-- /Dividend Name & Dividend Date -->

                        </div>
                        <!-- /Dividend Flex Inner -->

                        <!-- Amount & Action Items -->
                        <div class="flex-inner">

                          <!-- Amount & One Time -->
                          <div class="amount">
                            <p class="title">
                              <span>{{ currencySymbol }}</span>{{ getFormattedAmount(dividend.amount) }}
                            </p>
                            <p class="desc">
                              {{ dividend.oneTime === true ? 'One Time' : 'Constant' }}
                            </p>
                          </div>
                          <!-- /Amount & One Time -->

                          <!-- Actions Dropdown -->
                          <actions-dropdown
                            ref="itemDropdownWrapper"
                            :entity="dividend"
                            :delete-disabled="form.id === dividend.id"
                            :edit-disabled="form.id === dividend.id"
                            @edit="onEditDividend"
                            @copy="onCopyDividend"
                            @delete="onDeleteDividend"
                          />
                          <!-- /Actions Dropdown -->

                        </div>
                        <!-- /Amount & Action Items -->

                      </div>
                      <!-- /Dividend Card Top -->

                    </div>
                    <!-- /Dividends Added List -->

                  </div>
                  <!-- /Dividends Added -->

                </el-col>
                <!-- /Right Side -->

              </el-row>
            </div>
          </div>
          <!-- /Inner Wrapper -->
        </el-col>
        <!-- /Left Side -->

        <!-- Right Side -->
        <el-col :xl="10">
          <!-- Info Guide -->
          <info-guide-add-dialog
            class="mb-3 d-none d-xl-block"
            :visible="guideVisible"
            :title="$t('pages.businessGuide.profitAndCashFlow.addDialog.guide.dividends.title')"
            :text="$t('pages.businessGuide.profitAndCashFlow.addDialog.guide.dividends.text')"
            @click="onToggleDialogGuide"
          />
          <!-- Info Guide -->
        </el-col>
        <!-- /Right Side -->
      </el-row>

    </ib-dialog-container>
    <!-- /Content Wrapper -->

  </ib-dialog>
  <!-- /Dialog Add Dividends -->

</template>

<script>
import ActionsDropdown from '../../../Components/ActionsDropdown'
import AddDialogHeader from '@/components/_v2/Common/AddDialogHeader'
import FormSwitcher from '@/components/_v2/Form/FormSwitcher'
import IbOption from '@/components/_v2/Select/IbOption'
import InfoGuideAddDialog from '@/views/Home/StoryMode/Components/Guide/InfoGuideAddDialog.vue'
import MixinCloseMenu from '@/mixins/closeDropdownMenuOnScroll'
import MixinCurrencies from '@/mixins/currencies'
import MixinDialog from '@/mixins/dialog'
import MixinGuide from '@/mixins/guide'
import { mapActions } from 'vuex'

export default {
  name: 'DialogAddDividends',

  components: {
    InfoGuideAddDialog,
    ActionsDropdown,
    AddDialogHeader,
    FormSwitcher,
    IbOption
  },

  mixins: [
    MixinCloseMenu,
    MixinCurrencies,
    MixinDialog,
    MixinGuide
  ],

  props: {
    dialogAddDividendsVisible: {
      type: Boolean,
      default: false
    }
  },

  data () {
    const maxOccurDate = this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'Y').format('x')
    const minOccurDate = this.$moment(this.$store.state.idea.ideaSettings.startingFrom).format('x')

    return {
      loading: false,
      guideVisible: false,
      form: {
        id: 0,
        ideaId: this.$store.state.idea.id,
        name: '',
        amount: null,
        oneTime: true,
        distributionFrequency: '',
        whenWillItOccur: this.$store.state.idea.ideaSettings.startingFrom
      },
      focus: {
        distributionFrequency: false,
        whenWillItOccur: false
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < minOccurDate || time.getTime() >= maxOccurDate
        }
      },
      options: {
        distributionFrequencies: [
          {
            id: 1,
            label: this.$t('pages.businessGuide.profitAndCashFlow.addDialog.fields.dividends.distributedYearly'),
            value: 'yearly'
          },
          {
            id: 2,
            label: this.$t('pages.businessGuide.profitAndCashFlow.addDialog.fields.dividends.distributedMonthly'),
            value: 'monthly'
          }
        ]
      }
    }
  },

  computed: {
    isAddEnabled () {
      return this.form.name && this.form.amount && this.form.whenWillItOccur
    },

    isDialogOpen () {
      return this.dialogAddDividendsVisible
    }
  },

  methods: {
    ...mapActions('idea', [
      'deleteStoryModeDividend'
    ]),

    closeDialogOnButton () {
      this.$emit('close-dialog-on-button')
    },

    closeDialog () {
      this.$emit('close-dialog-add-dividends')
    },

    closedDialog () {
      this.resetForm()
      this.guideVisible = false
    },

    openDialog () {
      this.openGuideDialog('addDividendGuide')
      this.checkInputLabels()
    },

    onToggleDialogGuide () {
      this.guideVisible = !this.guideVisible
    },

    toggleFocus (input) {
      this.focus[input] = !this.focus[input]

      if (this.form[input]) {
        this.focus[input] = true
      }
    },

    resetForm () {
      this.form = {
        id: 0,
        ideaId: this.$store.state.idea.id,
        name: '',
        amount: null,
        oneTime: true,
        distributionFrequency: '',
        whenWillItOccur: this.$store.state.idea.ideaSettings.startingFrom
      }
      this.focus = {
        name: false,
        distributionFrequency: false,
        whenWillItOccur: false
      }
      this.checkInputLabels()
    },

    resetGuide () {
      if (this.$store.getters['idea/getProfitAndCashFlowDividends'].length === 0) {
        this.guideVisible = false
      }
    },

    saveDividend () {
      this.loading = true
      if (this.form.id === 0) {
        this.addDividend()
      } else {
        this.updateDividend()
      }
    },

    addDividend () {
      this.$http.post('story-mode/forecast/profit-and-cash-flow/dividends', this.form)
        .then((response) => {
          if (response.status === 201) {
            this.resetGuide()
            this.$store.commit('idea/addDividend', response.data.payload.dividend)
            this.resetForm()
          }
          this.$emit('on-update-profit-and-cash-flow-view')
        }).finally(() => {
          this.loading = false
        })
    },

    updateDividend () {
      this.$http.put(`story-mode/forecast/profit-and-cash-flow/dividends/${this.form.id}`, this.form)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('idea/updateDividend', response.data.payload.dividend)
            this.resetForm()
          }
          this.$emit('on-update-profit-and-cash-flow-view')
        }).finally(() => {
          this.loading = false
        })
    },

    onEditDividend (dividend) {
      this.copyDividendToForm(dividend)
    },

    onCopyDividend (dividend) {
      this.copyDividendToForm(dividend)
      this.form.id = 0
    },

    copyDividendToForm (dividend) {
      this.resetForm()
      this.form = Object.assign({}, dividend)
      this.form.ideaId = this.$store.state.idea.id
      this.checkInputLabels()
      this.scrollToTop()
    },

    onDeleteDividend (dividend) {
      this.deleteStoryModeDividend(dividend.id)
        .then((response) => {
          this.$emit('on-update-profit-and-cash-flow-view')
        })
    },

    checkInputLength (input) {
      this.focus[input] = !!this.form[input]
    },

    checkInputLabels () {
      this.checkInputLength('distributionFrequency')
      this.checkInputLength('whenWillItOccur')
    },

    changeToOneTime () {
      this.form.distributionFrequency = ''
      this.checkInputLength('numberOfInstallments')
    },

    changeToConstant () {
      this.form.distributionFrequency = 'yearly'
      this.checkInputLength('distributionFrequency')
    },

    getFormattedDate (date) {
      return this.$moment(date).format('MMM YYYY')
    }
  }
}
</script>
