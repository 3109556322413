<template>
  <div>
    <div class="mb-4 margin-guide-not-visible">
      <!-- Info Guide -->
      <info-guide
        v-if="guideVisibleFirstTime"
        type="profitAndCashFlowExamples"
        visible
        class="mb-3"
        @click="closeInfoFirstTime"
      />
      <!-- /Info Guide -->
      <div
        v-if="removeButtonVisible"
        class="d-flex justify-content-end mb-4"
      >
        <erase-icon-button @click="onDelete" />
      </div>
      <div
        class="d-flex justify-content-end"
      >
        <add-dividend
          class="mr-2"
          :disabled="!canEdit"
        />
        <set-cash-cycle
          class="mr-2"
          :disabled="!canEdit"
        />
        <set-income-taxes :disabled="!canEdit" />
      </div>
    </div>
    <template v-if="!isProfitAndCashFlowEmpty">
      <ib-card
        :animation="false"
        class="mb-4 pt-4 pb-1"
      >
        <profit-and-loss-projection />

      </ib-card>
      <ib-card
        :animation="false"
        class="mb-4 pt-4 pb-1"
      >
        <cash-flow-projection />

      </ib-card>
      <template v-if="showTables">

        <ib-card
          :animation="false"
          class="mb-4 pt-4 pb-1"
        >
          <profit-and-loss-table />
        </ib-card>

        <ib-card
          :animation="false"
          class="mb-4 pt-4 pb-1"
        >
          <cash-flow-table />
        </ib-card>
      </template>
    </template>

    <empty-placeholder
      v-else
    />

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="profitAndCashFlowExamples"
      :visible="guideVisible"
      @close="guideVisible = false;"
    />
    <!-- /Info Guide Drawer -->

    <!-- Ai Buddy Drawer -->
    <ai-buddy-drawer
      :visible="aiBuddyDrawerVisible"
      type="profit-and-cash-flow"
      @close="aiBuddyDrawerVisible = false"
    />
    <!-- /Ai Buddy Drawer -->
  </div>
</template>

<script>
import AddDividend from '@/views/Home/StoryMode/Forecast/ProfitAndCashFlow/AddDividend'
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import CashFlowProjection from '@/views/Home/StoryMode/FinancialPlan/Components/Overview/CashFlowProjection'
import CashFlowTable from '@/views/Home/StoryMode/Forecast/ProfitAndCashFlow/Tables/CashFlowTable'
import EmptyPlaceholder from '@/views/Home/StoryMode/FinancialPlan/Components/EmptyPlaceholder'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import EventBus from '@/event-bus'
import InfoGuide from '@/views/Home/StoryMode/Components/Example/InfoGuide.vue'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'
import MixinAiBuddyDrawer from '@/mixins/aiBuddyDrawer'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinTable from '@/mixins/table'
import ProfitAndLossProjection from '@/views/Home/StoryMode/FinancialPlan/Components/Overview/ProfitAndLossProjection'
import ProfitAndLossTable from '@/views/Home/StoryMode/Forecast/ProfitAndCashFlow/Tables/ProfitAndLossTable'
import SetCashCycle from '@/views/Home/StoryMode/Forecast/ProfitAndCashFlow/SetCashCycle'
import SetIncomeTaxes from '@/views/Home/StoryMode/Forecast/ProfitAndCashFlow/SetIncomeTaxes'
import { mapState } from 'vuex'

export default {
  name: 'ProfitAndCashFlow',

  components: {
    InfoGuide,
    AiBuddyDrawer,
    InfoGuideDrawer,
    AddDividend,
    CashFlowProjection,
    CashFlowTable,
    EmptyPlaceholder,
    EraseIconButton,
    ProfitAndLossProjection,
    ProfitAndLossTable,
    SetCashCycle,
    SetIncomeTaxes
  },

  mixins: [MixinGuide, MixinTable, MixinIdeaRoles, MixinApiCalculations, MixinDeleteIdeaExamples, MixinAiBuddyDrawer],

  data () {
    return {
      showTables: false
    }
  },

  computed: {
    ...mapState(['idea']),

    removeButtonVisible () {
      return this.canEdit && this.profitAndCashFlowHasItemsFromTemplate
    },

    isProfitAndCashFlowEmpty () {
      return !(
        this.idea.storyMode.forecast.revenueStreams.revenueStreams.length !== 0 ||
        this.idea.storyMode.forecast.directCosts.directCosts.length !== 0 ||
        this.idea.storyMode.setUp.staffAndPayrolls.staffAndPayrolls.length !== 0 ||
        this.idea.storyMode.forecast.marketingBudget.marketingElements.length !== 0 ||
        this.idea.storyMode.forecast.otherOverheads.otherOverheads.length !== 0 ||
        this.idea.storyMode.setUp.startupCosts.startupCosts.length !== 0 ||
        this.idea.storyMode.setUp.financing.finances.length !== 0
      )
    }
  },

  watch: {
    profitAndCashFlowHasItemsFromTemplate: {
      handler (value) {
        EventBus.$emit('has-items-from-template', value)
      },
      immediate: true
    }
  },

  mounted () {
    this.openGuide('profitCashFlowFinancialPlanGuide')

    EventBus.$on('open-info', () => {
      this.guideVisible = true
    })

    EventBus.$on('close-info-first-time', () => {
      this.guideVisibleFirstTime = false
    })

    EventBus.$on('remove-examples', () => {
      this.deleteExamples()
    })
  },

  beforeDestroy () {
    EventBus.$off('open-info')
    EventBus.$off('remove-examples')
    EventBus.$off('close-info-first-time')
  },

  methods: {
    deleteExamples () {
      this.deleteProfitAndCashFlowExamplesMixin()
        .then(_ => {
          this.loading = true
          this.getAllCalculations()
            .then(() => {
              this.loading = false
            })
            .finally(() => {
              this.loading = false
            })
        })
    }
  }
}
</script>
